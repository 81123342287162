<template>
  <div class="d-flex align-center">
    <v-card
      elevation="0"
      style="padding: 10px"
    >
      <v-responsive :aspect-ratio="1">
        <div
          class="d-flex align-center justify-center"
          style="height: 100%"
        >
          <v-icon>{{ icon }}</v-icon>
        </div>
      </v-responsive>
    </v-card>
    <div class="d-block overflow-hidden ml-2">
      <div class="text-body-2 font-weight-medium">
        {{ value }}
      </div>
      <div
        class="text-caption text--secondary text-truncate"
        style="margin-top: -2px"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DashboardIconRowDisplay',
    props: {
      icon: {},
      value: {},
      label: {},
    },
  }
</script>

<style scoped>

</style>
