<template>
  <large-base-card>
    <div class="d-flex align-center">
      <div>
        <div class="text-h4 font-weight-bold">
          {{ $t('InhouseTogoTitle') }}
        </div>
        <div class="text-body-2 text-subtitle-1">
          {{ $t('InhouseTogoDesc') }}
        </div>
      </div>
      <v-spacer/>
      <div
        class="d-flex align-center"
        style="height: 48px;"
      >
        <v-icon>mdi-finance</v-icon>
      </div>
    </div>
    <div class="mt-4">
      <div
        v-if="options"
        style="width: 100%;"
      >
        <div id="chart">
          <apexchart
            :options="options"
            :series="series"
            height="500"
            type="donut"
          />
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center justify-center"
        style="height: 100%;"
      >
        <v-progress-circular indeterminate/>
      </div>
    </div>
  </large-base-card>
</template>
<script>
  import LargeBaseCard from '@/composable/LargeBaseCard'
  import VueApexCharts from 'vue-apexcharts'
  import i18n from '@/i18n'

  export default {
    name: 'InhouseTogo',
    components: {
      LargeBaseCard,
      apexchart: VueApexCharts,
    },
    props: {
      date: {},
      info: {},
    },
    data () {
      return {
        items: {},
        loading: false,
        series: [],
        options: null,
      }
    },
    computed: {
      collectSalesData () {
        return this.info?.salesInfo?.consumeTypeInfo ?? []
      },
    },
    watch: {
      info: {
        handler: function (val) {
          this.reload()
        },
      },
    },
    async mounted () {
      await this.initialize()
    },
    methods: {
      async initialize () {
        this.loading = true
        await this.reload()
        this.loading = false
      },
      async reload () {
        this.loading = true
        this.options = null
        this.items = this.collectSalesData?.map(x => {
          return {
            name: x.printName ? x.printName : i18n.t('Other'),
            id: x.consumeTypeId,
            total: x.total ?? 0,
            lang: x.lang,
          }
        })
        const labels = this.items.length !== 0 ? this.items?.map(it => it.name) : [i18n.t('NoData')]
        const total = this.items?.reduce((sum, i) => sum + parseInt(i.total), 0)
        this.series = this.items.length !== 0 ? this.items?.map(it => Math.round((it.total * 100) / total)) : [100]

        this.options = {
          chart: {
            type: 'donut',
            selection: {
              enabled: false,
            },
          },
          tooltip: {
            enabled: true,
            theme: false,
            style: {
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.87)',
            },
            onDatasetHover: {
              highlightDataSeries: false,
            },
            y: {
              formatter: (seriesName) => seriesName + '%',
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'left',
            floating: false,
            fontSize: '14px',
            fontWeight: 200,
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
            onItemClick: {
              toggleDataSeries: false,
            },
            onItemHover: {
              highlightDataSeries: false,
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: true,
            textAnchor: 'left',
            style: {
              fontSize: '14px',
              fontFamily: '"Noto Sans SC", "Montserrat", sans-serif',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.286px',
              colors: ['transparent'],
            },
            background: {
              enabled: true,
              foreColor: 'rgba(0, 0, 0, 0.87)',
              padding: 4,
              borderRadius: 2,
              borderWidth: 0,
              borderColor: 'transparent',
              opacity: 0.9,
            },
            dropShadow: {
              enabled: false,
            },
          },
          states: {
            hover: {
              filter: {
                type: '',
                value: 0,
              },
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
              donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    offsetY: -10,
                    formatter: function (val) {
                      return val
                    },
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: '"Noto Sans SC", "Montserrat", sans-serif',
                    fontWeight: '700',
                    lineHeight: '20px',
                    letterSpacing: '0.286px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    offsetY: 0,
                    formatter: function (val) {
                      return val + '%'
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: this.items.length !== 0 ? i18n.t('Total') : i18n.t('NoData'),
                    fontSize: '18px',
                    fontFamily: '"Noto Sans SC", "Montserrat", sans-serif',
                    fontWeight: '700',
                    lineHeight: '20px',
                    letterSpacing: '0.286px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    formatter (val) {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          colors: ['#EF9A9A', '#C5CAE9', '#E6EE9C', '#00ffff', '#FFCCBC', '#7fcdfb', '#CFD8DC'],
          labels: labels,
        }
        this.loading = false
      },
    },
  }
</script>
