<template>
  <large-base-card>
    <div class="d-flex flex-wrap align-center">
      <div>
        <div class="text-h4 font-weight-bold">
          {{ $t('cashBook') }}
        </div>
        <div class="text-body-2 text-subtitle-1">
          {{ $t('DailyMoneyAccount') }}
        </div>
      </div>
      <v-spacer/>
      <div
        class="d-flex align-center"
        style="height: 48px;"
      >
        <v-icon>mdi-book</v-icon>
      </div>

      <v-card
        class="pt-4 overflow-hidden overflow-y-auto"
        style="height: 400px; width: 100%;"
        elevation="0"
      >
        <template v-if="getNonOperatingIncome(billData).length > 0">
          <template
            v-for="item in getNonOperatingIncome(billData)"
          >
            <div
              :key="item.key"
              class="d-flex align-end py-2"
            >
              <div class="ml-0 mr-auto d-flex flex-wrap">
                <div style="width: 100%">
                  {{ item.cashAccountNote }}
                </div>
                <div class="text-body-1 font-weight-light">
                  {{ item.orderId }}@{{ formatDate(item.updateTimestamp) }}
                </div>
              </div>
              <div class="ml-auto mr-0">
                <div
                  class="text-h3 font-weight-thin"
                  :style="(item.payLogAmount >= 0)?{color:'#4CAF50'}:{color:'#1b5e20'}"
                >
                  <template
                    v-if="item.payLogAmount >= 0"
                  >
                    <div class="d-flex align-center">
                      <div class="pt-1 text-h4">
                        +
                      </div>
                      <div>{{ item.payLogAmount | priceDisplay }}</div>
                    </div>
                  </template>
                  <template v-else>
                    {{ item.payLogAmount | priceDisplay }}
                  </template>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <v-card
            elevation="0"
            class="d-flex justify-center align-center"
            height="100%"
          >
            <div>
              <div class="d-flex align-center justify-center flex-wrap">
                <div class="mt-2">
                  <v-icon
                    class="order-0"
                    size="125px"
                    color="#eee"
                  >
                    mdi-file-document-alert
                  </v-icon>
                </div>
                <div
                  class="mt-2"
                  :style="$vuetify.breakpoint.smAndUp ? $vuetify.breakpoint.mdAndUp ? 'width: 80%' : 'width: 70%' : 'width: 90%'"
                >
                  <div>{{ $t('noDataNow') }}</div>
                </div>
              </div>
            </div>
          </v-card>
        </template>
      </v-card>
    </div>
  </large-base-card>
</template>

<script>
  import LargeBaseCard from '@/composable/LargeBaseCard'
  import CashBook from '@/model/bills/cashbook/CashBook'
  import Payment from '@/model/Payment'
  import dayjs from 'dayjs'

  export default {
    name: 'CashbookInfo',
    components: {
      LargeBaseCard,
    },
    props: {
      date: {},
    },
    data () {
      return {
        payMethods: {},
        billData: {},
        model: CashBook,
        loading: false,
      }
    },
    computed: {
      filter () {
        return {
          dateFilter: this.date,
        }
      },
    },
    watch: {
      date: {
        handler: function (val) {
          this.reload()
        },
      },
    },
    async mounted () {
      await this.initialize()
    },
    methods: {
      getNonOperatingIncome (income) {
        const res = Object.values(income).filter(x => x.cashAccountNote.toLowerCase() !== 'umsatz' && x.cashAccountNote.toLowerCase() !== 'trinkgeld')
        return res
      },
      formatDate (date) {
        const res = dayjs(date).format('HH:mm:ss')
        return res
      },
      async initialize () {
        await this.reload()
      },
      async reload () {
        this.loading = true
        this.payMethods = await Payment.getList(true)
        this.billData = await CashBook.getList(true, this.filter)
        this.loading = false
      },
    },
  }
</script>
