import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

import StringEntity from '@/model/common/StringEntity'
import GlobalSettings, { GlobalConfigLang } from '@/GlobalSettings'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
  },
  langs: {
    displayName: 'paymentname',
    type: IKDataEntity.Types.Group,
    formConfig: {
      groupName: 'paymentname',
      default: GlobalSettings.supportLang,
      childLabelKey: 'lang',
      type: {},
    },
    tableConfig: {
      displayCondition: (item) => {
        return item.lang.toUpperCase() === GlobalConfigLang.lang.toUpperCase()
      },
    },
    childKey: 'name',
    children: [
      StringEntity, StringEntity, StringEntity,
    ],
  },
  fixedDisplay: {
    type: IKDataEntity.Types.Boolean,
  },
  isOnline: {
    displayName: 'isOnline',
    type: IKDataEntity.Types.Boolean,
  },
  apiKey: {

    formConfig: {
      required: false,
      requiredNew: false,
      requiredEdit: false,
    },
  },
}
const config = {
  add: function (item) {
    return hillo.post('PayMethod.php?op=add', {
      ...item,
      langs: JSON.stringify(item.langs),
    }, {
      showLoading: true,
    })
  },
  edit: function (item) {
    return hillo.postWithUploadFile('PayMethod.php?op=update', {
      ...item,
      langs: JSON.stringify(item.langs),
    }, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('PayMethod.php?op=delete', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('PayMethod.php', {
      ...filter,
    })).content.map(p => {
      p.filterSet = 'paymentMethod'
      return p
    })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
